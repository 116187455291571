/** @format */
.ltn__header-4 .ltn__header-middle-area,
.ltn__header-5 .ltn__header-middle-area {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.carousel-caption h3 {
  font-size: 45px;
}
.carousel-caption h4 {
  font-size: 22px;
  color: white;
}
.carousel-slide {
  position: relative;
  height: 70vh;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-slide .carousel-caption {
  position: absolute;
  bottom: 200px;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.1); /* Overlay color and opacity */
  color: white;
  padding: 10px;
  color: green !important;
}
.footer-widget {
  margin-bottom: 0px !important;
}
@media (max-width: 1599px) {
  .ltn__section-title-2 .section-title {
    font-size: 45px !important;
  }
}
.ant-btn-icon {
  position: relative !important;
  top: -12px !important;
  right: 8px !important;
}
@media screen and (max-width: 756px) {
  .carousel-slide .carousel-caption {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.1); /* Overlay color and opacity */
    color: white;
    padding: 10px;
    color: green !important;
  }
  .carousel-caption h3 {
    font-size: 30px;
  }
}
a {
  cursor: pointer !important;
}
